// src/pages/TermsOfService.js
import React from 'react';

const TermsOfService = () => {
  return (
    <div className="container my-5">
      <h1>Conditions d'utilisation</h1>
      <p>Bienvenue sur KOFFRIR. En utilisant notre site web, vous acceptez les conditions d'utilisation suivantes :</p>
      
      <h2>1. Acceptation des conditions</h2>
      <p>En accédant et en utilisant notre site web, vous acceptez les présentes conditions d'utilisation et vous vous engagez à les respecter. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser notre site.</p>
      
      <h2>2. Utilisation du site</h2>
      <p>Vous vous engagez à utiliser notre site uniquement à des fins légales et en conformité avec toutes les lois et réglementations applicables. Vous ne devez pas utiliser notre site pour transmettre tout contenu illégal, nuisible, menaçant, abusif, harcelant, diffamatoire, obscène ou autrement répréhensible.</p>
      
      <h2>3. Propriété intellectuelle</h2>
      <p>Tout le contenu présent sur notre site, y compris, mais sans s'y limiter, les textes, graphiques, logos, icônes, images et logiciels, est la propriété de KOFFRIR ou de ses fournisseurs de contenu et est protégé par les lois sur le droit d'auteur et les autres lois sur la propriété intellectuelle.</p>
      
      <h2>4. Limitation de responsabilité</h2>
      <p>Nous ne serons pas responsables des dommages directs, indirects, accessoires, spéciaux ou consécutifs résultant de l'utilisation ou de l'incapacité à utiliser notre site, même si nous avons été informés de la possibilité de tels dommages.</p>
      
      <h2>5. Modifications des conditions</h2>
      <p>Nous nous réservons le droit de modifier ces conditions d'utilisation à tout moment. Les modifications entreront en vigueur dès leur publication sur notre site. Votre utilisation continue de notre site après la publication des modifications constitue votre acceptation des nouvelles conditions.</p>
      
      <h2>6. Contact</h2>
      <p>Si vous avez des questions concernant ces conditions d'utilisation, veuillez nous contacter à l'adresse suivante : contact@koffrir.fr.</p>
    </div>
  );
};

export default TermsOfService;