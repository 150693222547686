import React from 'react';

const News = () => {
  return (
    <div className="container my-5">
      <h1>Actualités du site</h1>
      <p>Suivez ici les mises à jour et nouveautés du site.</p>
      
      <div className="news-list mt-4">
        <div className="news-item mb-3 p-3 border rounded shadow-sm">
          <h2>Version 1.0.0</h2>
          <p>Ajout du Captcha sur la page de Connexion.</p>
          <p>Fournir une quatrième réponse dans le questionnaire.</p>
          <p>Modification de la page "liste".</p>
          <p>Faire cette page sur l'actualité du site.</p>
          <p>Rediriger les mauvaises requêtes sur l'accueil.</p>
          <p>Enlever la log inutile en prod.</p>
          <p>Traitement de certains bugs.</p>
        </div>

        {/* Vous pourrez ajouter d'autres mises à jour ici */}
      </div>
    </div>
  );
};

export default News;
