// src/components/About.js
import React from 'react';

const About = () => {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-12">
          <h1 className="text-center">À propos de nous</h1>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-6">
          <h2>Notre Histoire</h2>
          <p>
            Bienvenue sur notre site ! Nous sommes dédiés à la création d'une plateforme
            où les utilisateurs peuvent trouver des cadeaux pour leurs proches ou même pour eux-mêmes.
            Depuis notre lancement, nous nous efforçons de bâtir une communauté de personnes partageant les mêmes idées :
            celles qui croient que le don est une forme d'art et que chaque cadeau raconte une histoire.
          </p>
          <p>
            Notre équipe, bien que restée anonyme, est composée de passionnés de cadeaux. Nous avons parcouru le monde à la
            recherche d'articles uniques et inspirants. Notre objectif est de vous fournir une plateforme où vous pouvez
            facilement trouver des cadeaux qui feront sourire vos proches.
          </p>
          <h2>Notre Mission</h2>
          <p>
            Notre mission est de faciliter la recherche et la découverte de cadeaux uniques et spéciaux. Que ce soit pour un
            anniversaire, une fête ou simplement pour faire plaisir, notre site est là pour vous aider. Nous croyons que chaque
            cadeau, qu'il soit grand ou petit, doit être significatif.
          </p>
          <p>
            Nous mettons en avant des produits de haute qualité provenant de créateurs et artisans du monde entier. Notre
            plateforme est conçue pour être intuitive et facile à utiliser, vous permettant ainsi de trouver rapidement le cadeau
            parfait. Nous nous engageons également à offrir un service client exceptionnel pour que votre expérience de
            magasinage soit agréable du début à la fin.
          </p>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <h2>Pourquoi utiliser notre site ?</h2>
          <p>
            Nous offrons une vaste sélection de cadeaux soigneusement sélectionnés pour répondre à tous les goûts et à tous les
            budgets. Grâce à notre interface conviviale, trouver le cadeau parfait n'a jamais été aussi simple. Vous pouvez
            naviguer par catégorie, occasion ou même par personnalité pour trouver exactement ce que vous cherchez.
          </p>
          <p>
            En plus de notre large sélection, nous proposons des guides d'achat et des articles de blog pour vous inspirer et vous
            conseiller. Nous croyons que chaque cadeau doit être une expression de l'affection et de l'appréciation, c'est pourquoi
            nous prenons le temps de choisir des produits qui reflètent ces valeurs.
          </p>
          <p>
            En utilisant notre site, vous soutenez également des petites entreprises et des artisans du monde entier. Nous
            croyons en la promotion du commerce équitable et éthique, et nous faisons de notre mieux pour vous proposer des
            produits qui respectent ces principes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
