const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';

// api.js

export const postQuestionnaire = async (data) => {
  try {
    

    const response = await fetch(`${backendUrl}/api/questionnaire`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Erreur lors de la communication avec le backend Node.js', error);
    throw error;
  }
};

export const postClassement = async (data) => {
  try {
    

    const response = await fetch(`${backendUrl}/api/classement`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Erreur lors de la communication avec le backend Node.js', error);
    throw error;
  }
};


export const finQuestionnaire = (data) => {
  try {
    fetch(`${backendUrl}/api/questionnaire/fin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  } catch (error) {
    console.error('Erreur lors de la communication avec le backend Node.js', error);
    throw error;
  }
};

export const getQuestionnaire = async (nbQuestion) => {
  try {
    const response = await fetch(`${backendUrl}/api/questionnaire/${nbQuestion}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Erreur lors de la communication avec le backend Node.js', error);
    throw error;
  }
};

export const getClassementAlphabetique = async (limit = 10, page = 1) => {
  try {
    const response = await fetch(`${backendUrl}/api/classement/alphabetique/page?limit=${limit}&page=${page}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    // Imprimer la réponse dans la console
    const data = await response.json();

    // Imprimer la réponse dans la console
    console.log('Réponse du classement alphabétique:', data);

    return data;
  } catch (error) {
    console.error('Erreur lors de la récupération du classement alphabétique', error);
    throw error;
  }
};
// Fonction pour obtenir le nombre total de cadeaux
export const getTotalCadeauxCount = async () => {
  try {
    const response = await fetch(`${backendUrl}/api/classement/alphabetique`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData.totalCount; // Supposons que le backend renvoie un { totalCount: 100 }
  } catch (error) {
    console.error('Erreur lors de la récupération du nombre total de cadeaux', error);
    throw error;
  }
};


export default backendUrl;