// src/pages/PrivacyPolicy.js
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container my-5">
      <h1>Politique de confidentialité et de gestion des cookies</h1>
      <p>Chez KOFFRIR, nous respectons votre vie privée et nous nous engageons à protéger vos informations personnelles. Cette politique explique comment nous collectons, utilisons et partageons vos informations et comment nous utilisons les cookies sur notre site.</p>
      
      <h2>1. Collecte des informations</h2>
      <p>Nous pouvons collecter des informations personnelles lorsque vous utilisez notre site, comme votre nom, adresse e-mail et toute autre information que vous choisissez de nous fournir. Nous recueillons également des informations automatiquement via des cookies et d'autres technologies similaires.</p>
      
      <h2>2. Utilisation des informations</h2>
      <p>Nous utilisons les informations collectées pour :</p>
      <ul>
        <li>Fournir et améliorer nos services</li>
        <li>Personnaliser votre expérience sur notre site</li>
        <li>Communiquer avec vous</li>
        <li>Analyser l'utilisation de notre site</li>
        <li>Diffuser des publicités, y compris via Google AdSense</li>
      </ul>
      
      <h2>3. Partage des informations</h2>
      <p>Nous ne vendons pas vos informations personnelles à des tiers. Nous pouvons partager vos informations avec des fournisseurs de services tiers qui nous aident à exploiter notre site et à fournir nos services, ainsi qu'avec des partenaires publicitaires comme Google AdSense.</p>
      
      <h2>4. Utilisation des cookies</h2>
      <p>Nous utilisons des cookies pour améliorer votre expérience sur notre site. Les cookies sont de petits fichiers texte placés sur votre appareil pour collecter des informations standard d'enregistrement Internet et des informations sur le comportement des visiteurs. Vous pouvez choisir d'accepter ou de refuser les cookies en modifiant les paramètres de votre navigateur. Toutefois, le refus des cookies peut affecter la fonctionnalité de notre site.</p>
      
      <h2>5. Sécurité des informations</h2>
      <p>Nous prenons des mesures raisonnables pour protéger vos informations personnelles contre la perte, l'utilisation abusive et l'accès non autorisé, la divulgation, la modification ou la destruction.</p>
      
      <h2>6. Vos droits</h2>
      <p>Vous avez le droit d'accéder à vos informations personnelles, de les corriger, de les supprimer ou de limiter leur traitement. Pour exercer ces droits, veuillez nous contacter à l'adresse suivante : contact@koffrir.fr.</p>
      
      <h2>7. Modifications de la politique</h2>
      <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les modifications entreront en vigueur dès leur publication sur notre site. Votre utilisation continue de notre site après la publication des modifications constitue votre acceptation de la nouvelle politique.</p>
      
      <h2>8. Contact</h2>
      <p>Si vous avez des questions concernant cette politique de confidentialité et de gestion des cookies, veuillez nous contacter à l'adresse suivante : contact@koffrir.fr.</p>
    </div>
);
};

export default PrivacyPolicy;