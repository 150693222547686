import React, { useState } from 'react';
import Captcha from '../components/captcha/Captcha';
import { useNavigate } from 'react-router-dom';

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    reason: '',
    captcha: '',
  });
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCaptchaChange = (value) => {
    setFormData({
      ...formData,
      captcha: value,
    });
  };

  const handleCaptchaTokenChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newErrors = {};
    
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = 'Ce champ est obligatoire';
      }
    });
    
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    
    try {
      setErrors({});
      setSuccess('');
      const response = await fetch(`${backendUrl}/auth/validateCaptchaWithoutCookies`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${captchaToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        setErrors({ captcha: 'Captcha incorrect.' });
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      // Envoi du mail (simulation)
      await fetch(`${backendUrl}/auth/send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: 'toto@test.toto',
          subject: 'Nouveau message de contact',
          text: `Prénom: ${formData.firstName}\nNom: ${formData.lastName}\nEmail: ${formData.email}\nRaison: ${formData.reason}`,
        }),
      });
      
      setSuccess('Message envoyé avec succès.');
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        reason: '',
        captcha: '',
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container mt-5">
      <h2>Contactez-nous</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="firstName" className="form-label">Prénom *</label>
          <input
            type="text"
            className="form-control"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
          {errors.firstName && <p style={{ color: 'red' }}>{errors.firstName}</p>}
        </div>
        <div className="mb-3">
          <label htmlFor="lastName" className="form-label">Nom *</label>
          <input
            type="text"
            className="form-control"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
          {errors.lastName && <p style={{ color: 'red' }}>{errors.lastName}</p>}
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email *</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
        </div>
        <div className="mb-3">
          <label htmlFor="reason" className="form-label">Raison de contact *</label>
          <select
            className="form-select"
            id="reason"
            name="reason"
            value={formData.reason}
            onChange={handleChange}
          >
            <option value="">Sélectionner une raison</option>
            <option value="site">Je ne comprends pas votre site</option>
            <option value="cookies">Utilisation des cookies</option>
            <option value="usage">Comment utiliser votre site</option>
            <option value="other">J'ai une demande particulière</option>
          </select>
          {errors.reason && <p style={{ color: 'red' }}>{errors.reason}</p>}
        </div>
        <Captcha onCaptchaChange={handleCaptchaChange} onCaptchaTokenChange={handleCaptchaTokenChange} />
        {errors.captcha && <p style={{ color: 'red' }}>{errors.captcha}</p>}
        <button type="submit" className="btn btn-primary">Envoyer</button>
        {success && <p style={{ color: 'green' }}>{success}</p>}
      </form>
    </div>
  );
};

export default ContactForm;
