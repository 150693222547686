import React, { useEffect, useState } from 'react';
import { getClassementAlphabetique, getTotalCadeauxCount } from '../services/api';
import Cadeau from '../components/cadeau/Cadeau';

const Bibliotheque = () => {
    const [cadeaux, setCadeaux] = useState([]);  // Initialisation comme tableau vide
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalCadeaux, setTotalCadeaux] = useState(null);
    const [loading, setLoading] = useState(true);  // Indicateur de chargement

    // Récupérer le nombre total de cadeaux une seule fois
    useEffect(() => {
        const fetchTotalCadeauxCount = async () => {
            try {
                const totalCadeaux = await getTotalCadeauxCount();
                setTotalCadeaux(totalCadeaux);
                // Calculer le nombre total de pages après avoir récupéré totalCadeaux
                
            } catch (error) {
                console.error("Erreur lors de la récupération du nombre total de cadeaux", error);
            }
        };
        const totalPages = Math.ceil(totalCadeaux / limit);
        setTotalPages(totalPages);
        if (totalCadeaux === null) {
            fetchTotalCadeauxCount();
        }
    }, [totalCadeaux, limit]); // Ce useEffect ne s'exécute qu'une fois pour récupérer le total des cadeaux

    // Récupérer les cadeaux en fonction de la page et du limit
    useEffect(() => {
        const fetchCadeaux = async () => {
            setLoading(true);  // Démarre le chargement
            try {
                const response = await getClassementAlphabetique(limit, page);
                setCadeaux(response);
                setLoading(false);  // Fin du chargement
            } catch (error) {
                console.error("Erreur lors de la récupération des cadeaux", error);
                setLoading(false);  // Fin du chargement en cas d'erreur
            }
        };

        if (totalCadeaux !== null) {
            fetchCadeaux();
        }
    }, [page, limit, totalCadeaux]); // Ce useEffect se déclenche lorsque limit ou page change

    if (loading) {
        return <div>Chargement des cadeaux...</div>;  // Afficher un message de chargement
    }

    return (
        <div className="container mt-5">
            <h2>Bibliothèque des cadeaux</h2>
            <div className="mb-3">
                <label>Afficher par :</label>
                <select value={limit} onChange={(e) => setLimit(Number(e.target.value))}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                </select>
            </div>

            {/* Affichage des cadeaux avec slice et vérification si `cadeaux` existe */}
            {cadeaux && cadeaux.length > 0 && (
                <div className="d-flex flex-column gap-3">
                    {cadeaux.slice(0, limit).map((cadeau) => (
                        <div key={cadeau.id} className="p-3 border rounded shadow-sm">
                            <Cadeau name={cadeau.name} lien={cadeau.lien} prix={cadeau.prix} />
                        </div>
                    ))}
                </div>
            )}

            {cadeaux && cadeaux.length === 0 && (
                <div>Aucun cadeau disponible.</div>  // Afficher un message si aucun cadeau n'est disponible
            )}

            <div className="mt-4 d-flex justify-content-between">
                <button onClick={() => setPage(1)} disabled={page === 1} className="btn btn-primary">Première</button>
                <button onClick={() => setPage(page - 1)} disabled={page === 1} className="btn btn-secondary">Précédente</button>
                <span>Page {page} / {totalPages}</span>
                <button onClick={() => setPage(page + 1)} disabled={page === totalPages} className="btn btn-secondary">Suivante</button>
                <button onClick={() => setPage(totalPages)} disabled={page === totalPages} className="btn btn-primary">Dernière</button>
            </div>
        </div>
    );
};

export default Bibliotheque;
